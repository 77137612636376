<template>
  <div>
    <CacheSetup />
  </div>
</template>

<script>
import CacheSetup from "@/components/Settings/CacheSetup.vue";
export default {
  name: "Blocking",
  components: { CacheSetup },
};
</script>

<style scoped></style>
