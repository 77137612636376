<template>
  <div>
    <div class="text-h5 pt-5 mb-10">Кэш</div>
    <v-row class="mb-5">
      <v-col cols="auto">
        <v-btn :loading="pending" color="accent" @click="clear('all')"
          >Сбросить весь кэш</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn :loading="pending" color="accent" @click="clear('nuxt')"
          >Сбросить Nuxt кэш</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn :loading="pending" color="accent" @click="clear('api')"
          >Сбросить Api конфиг</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "../../../api";
export default {
  name: "CacheSetup",
  data() {
    return {
      pending: false,
    };
  },
  methods: {
    async clear(type) {
      try {
        this.pending = true;
        switch (type) {
          case "all":
            await api.settings.cache.clearAll();
            break;
          case "nuxt":
            await api.settings.cache.clearNuxt();
            break;
          case "api":
            await api.settings.cache.clearApi();
            break;
          default:
        }
        this.pending = false;
        this.$store.dispatch("showSnackbar", {
          msg: "Кэш сброшен",
          color: "success",
          show: true,
        });
      } catch (e) {
        this.pending = false;
        this.$store.dispatch("showSnackbar", {
          msg: "Ошибка сброса кэша",
          color: "error",
          show: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
